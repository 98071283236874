import React, { Component } from 'react';
import './SongView.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RenderSong } from './SongRenderer';
import { AnnotationModes, AnnotationSettings } from '../lib/annotate';

const KeySettings = ({ currentKey, offset, update, reset }) => {
  let keyResetClass = "key-reset";
  if (offset !== 0) {
    keyResetClass += " able";
  }

  return (
    <div className="key-settings">
      <div>Key:</div>
      <div className="mods">
        <div className="mod" onClick={() => update(-1)} role="button">
          –
        </div>
        <div className="key">{currentKey}</div>
        <div className="mod" onClick={() => update(1)} role="button">
          +
        </div>
      </div>
      <div className={keyResetClass} onClick={reset} role="button">
        Reset
      </div>
    </div>
  );
};

class SongView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          transpositionOffset: 0,
          annotationMode: AnnotationModes.FURIGANA,
        };
    }

    getCurrentSong() {
        for (var i = 0; i < this.props.songs.length; i++) {
            if (this.props.songs[i].key === this.props.match.params.key) {
                return(this.props.songs[i]);
            }
        }
    }

    componentDidMount() {
        var currSong = this.getCurrentSong();
        if (currSong) {
            document.title = currSong.title + " | Figs Foundation";
        }
    }

    updateTranspositionOffset = (adjustment) => {
        this.setState(prevState => {
            return { transpositionOffset: prevState.transpositionOffset + adjustment }
        })
    }

    resetTranspositionOffset = () => {
        this.setState({
            transpositionOffset: 0
        });
    }

    render() {
        var text = "";
        var currSong = this.getCurrentSong()
        if (currSong) {
            text = currSong.lyrics;
        } else {
            return (
                <p>We don't have a song with that title.</p>
            )
        }

        text = text.split("\n")
        const {items, key} = RenderSong(text, this.state.annotationMode, this.state.transpositionOffset);

        // console.log("transpositionOffset", this.state.transpositionOffset);
        return (
          <div className="song-display">
            <div className="song-display-settings">
              <Link className="edit" to={"/edit/" + this.props.match.params.key}>
                Edit song
              </Link>
              <AnnotationSettings
                currentMode={this.state.annotationMode}
                updateMode={mode => this.setState({ annotationMode: mode })} />
              {key &&
                <KeySettings
                  currentKey={key}
                  offset={this.state.transpositionOffset}
                  update={this.updateTranspositionOffset}
                  reset={this.resetTranspositionOffset} />
              }
            </div>
            <div className="song-display-content">
              {items}
            </div>
          </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        songs: state.songs,
    }
}

export default connect(mapStateToProps, {})(SongView);
