import React from 'react';

class Newsletter extends React.Component {
    componentDidMount() {
        document.title = "Newsletter | Figs Foundation";
    }
    render() {
        return (
            <div>
                <p>Fill out this form and you will be automatically added to our mailing list.</p>
                <iframe title="mailing-list-signup" src="https://docs.google.com/forms/d/e/1FAIpQLScXtmqRxg8Qu3cJtoJxfOGrAedJeFGy1O70VV2oZtA1K5yMZg/viewform?embedded=true" width="640" height="777" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
            
        );
    }
};

export default Newsletter;