import BrandLink from './BrandLink'
import React from 'react';
import { annotate } from '../lib/annotate';
import { transpose } from 'chord-transposer';

const TITLE_TYPE = "#title ";
const SUBTITLE_TYPE = "#subtitle ";
const KEY_TYPE = "#key ";
const SECTION_TYPE = "#section ";
const CHORDS_TYPE = "#chords ";
const YOUTUBE_TYPE = "#youtube ";
const SOUNDCLOUD_TYPE = "#soundcloud ";
const LYRICS_TYPE = "lyrics";

function ParseSong(text) {
    const song = {};
    song.metadata = {};
    song.lines = [];

    text.forEach(line => {
        if (line.startsWith(TITLE_TYPE)) {
            song.metadata.title = line.slice(TITLE_TYPE.length);
        } else if (line.startsWith(CHORDS_TYPE)) {
            song.lines.push({
                type: CHORDS_TYPE,
                value: line.slice(CHORDS_TYPE.length)
            });
        } else if (line.startsWith(SECTION_TYPE)) {
            song.lines.push({
                type: SECTION_TYPE,
                value: line.slice(SECTION_TYPE.length)
            });
        } else if (line.startsWith(SUBTITLE_TYPE)) {
            song.metadata.subtitle = line.slice(SUBTITLE_TYPE.length);
        } else if (line.startsWith(KEY_TYPE)) {
            song.metadata.key = line.slice(KEY_TYPE.length);
        } else if (line.startsWith(YOUTUBE_TYPE)) {
            song.metadata.youtube = line.slice(YOUTUBE_TYPE.length);
        } else if (line.startsWith(SOUNDCLOUD_TYPE)) {
            song.metadata.soundcloud = line.slice(SOUNDCLOUD_TYPE.length);
        } else if (line.length) { // Just a normal line of lyrics
            song.lines.push({
                type: LYRICS_TYPE,
                value: line,
            });
        }
    })

    return song;
}

const MediaLinks = ({ metadata }) => {
    const { youtube, soundcloud } = metadata;
    if (!(youtube || soundcloud)) {
        return null;
    }
    return (
        <div className="media-links">
            <span className="media-links-title">Listen here:</span>
            {youtube && <BrandLink brand="youtube" href={youtube} />}
            {soundcloud && <BrandLink brand="soundcloud" href={soundcloud} />}
        </div>
    );
};

export function RenderSong(text, annotationMode = undefined, transposeOffset = 0) {
  const song = ParseSong(text);

  const header = (
    <div>
      {song.metadata.title &&
        <h2>{song.metadata.title}</h2>
      }
      {song.metadata.subtitle &&
        <div className="subtitle">
          <p>{song.metadata.subtitle}</p>
        </div>
      }
      <MediaLinks metadata={song.metadata} />
    </div>
  );

  const bodyItems = [];
  for (const line of song.lines) {
    if (line.type === CHORDS_TYPE) {
      bodyItems.push(
        <p className="song-chords">
          {song.metadata.key
            ? transpose(line.value).fromKey(song.metadata.key).up(transposeOffset).toString()
            : line.value}
        </p>
      );
    } else if (line.type === SECTION_TYPE) {
      bodyItems.push(
        <p className="song-section">{line.value}</p>
      );
    } else if (line.type === LYRICS_TYPE) {
      const parts = annotate(line.value, annotationMode);
      bodyItems.push(<p className="song-lyrics">{parts}</p>);
    }
  }
  const body = (
    <div className="song-body">
      {bodyItems}
    </div>
  );

  const items = [header, body];
  const key = song.metadata.key
    ? transpose(song.metadata.key).fromKey(song.metadata.key).up(transposeOffset).toString()
    : null;

  return {
    items,
    key,
  };
}
