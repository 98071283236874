import React from 'react';

class Donate extends React.Component {
    componentDidMount() {
        document.title = "Donate | Figs Foundation";
    }

    render() {
        return (
            <p>We aren't accepting donations at this time.</p>
        );
    }
};

export default Donate;