import { UPDATE_SONG_TEXT, KEEP_SONGS_WITH_SEARCH_QUERY, TOGGLE_SHOW_CHORDS, TOGGLE_SHOW_SECTION_HEADERS } from '../actions';
import songs from '../data/songs.json';

const initialState = {
    showChords: true,
    showSectionHeaders: true,
    searchResults: [],
    songs: songs,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SONG_TEXT:
            return {
                ...state,
                song: action.payload.updatedText
            }
        case TOGGLE_SHOW_CHORDS:
            return {
                ...state,
                showChords: !state.showChords
            }
        case TOGGLE_SHOW_SECTION_HEADERS:
            return {
                ...state,
                showSectionHeaders: !state.showSectionHeaders
            }
        case KEEP_SONGS_WITH_SEARCH_QUERY:
            var searchResults = [];
            for (var i = 0; i < state.songs.length; i++) {
                var caseInsensitiveLyrics = state.songs[i].lyrics.toLowerCase();
                var caseInsensitiveQuery = action.payload.searchQuery.toLowerCase();
                if (caseInsensitiveLyrics.includes(caseInsensitiveQuery)) {
                    searchResults.push(state.songs[i]);
                }
            }
            return {
                ...state,
                searchResults: searchResults
            }
        default:
            return state;
    }
};
