import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import logo from '../assets/figsfoundation_logo.png';

const Header = props => {
    return (
        <div className="header">
          <div className="header-content">
            <div className="header-logo">
              <Link to="/"><img src={logo} alt="Figs Foundation Logo" /></Link>
            </div>
            <div className="header-nav-buttons">
              <Link to="/">Songs</Link>
              <Link to="/about">About</Link>
              <a href="https://forms.gle/kyBs1D16CyimqErW9" target="_blank" rel="noopener noreferrer">
                Submit a Song
              </a>
            </div>
          </div>
        </div>
    );
};

export default Header;
