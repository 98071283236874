import './Header.css';
import BrandLink from './BrandLink';
import React, { Component } from 'react';

class About extends Component {
    componentDidMount() {
        document.title = "About | Figs Foundation";
    }

    render() {
        return (
            <div className="about">
                <h3>Mission</h3>
                <p>Spread the good news of Jesus Christ to Japanese people in Japan and all around the world through music.</p>

                <h3>Beliefs</h3>

                <p>Love the Lord your God with all your heart and with all your soul and with all your mind.</p>
                <br />
                <p>Love your neighbor as yourself.</p>
                <br />
                <p>For God so loved the world, that he gave his only Son, that whoever believes in him should not perish but have eternal life.</p>

                <h3>Our Story</h3>

                <p>The Figs Foundation was founded on April 28th, 2017 by Conor and Yuka Livingston. It is named after a Bible study that they helped lead while studying at Hope College in Holland, Michigan. Figs stands for "friends in God."</p>
                <br />
                <p>Big thanks to Jack Cogdill, who has made significant contributions to the software components of this project.</p>

                <h3>Social Media</h3>
                <BrandLink brand="soundcloud" href="https://soundcloud.com/figsfoundation" />
                <BrandLink brand="twitter" href="https://twitter.com/figsfoundation" />
                <BrandLink brand="facebook" href="https://www.facebook.com/figsfoundation" />
                <BrandLink brand="instagram" href="https://www.instagram.com/figsfoundation" />
            </div>
        );
    }
}


export default About;
