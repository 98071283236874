export const UPDATE_SONG_TEXT = "UPDATE_SONG_TEXT";
export const TOGGLE_SHOW_CHORDS = "TOGGLE_SHOW_CHORDS";
export const TOGGLE_SHOW_SECTION_HEADERS = "TOGGLE_SHOW_SECTION_HEADERS";
export const KEEP_SONGS_WITH_SEARCH_QUERY = "KEEP_SONGS_WITH_SEARCH_QUERY";

export const updateSongText = (updatedText, key) => {
    return {
        type: UPDATE_SONG_TEXT,
        payload: {
            updatedText: updatedText,
            key: key
        }
    }
}

export const toggleShowChords = () => {
    return {
        type: TOGGLE_SHOW_CHORDS,
        payload: {}
    }
}

export const toggleShowSectionHeaders = () => {
    return {
        type: TOGGLE_SHOW_SECTION_HEADERS,
        payload: {}
    }
}

export const keepSongsWithSearchQuery = (searchQuery) => {
    return {
        type: KEEP_SONGS_WITH_SEARCH_QUERY,
        payload: {
            searchQuery: searchQuery
        }
    }
}