import React from 'react';
import hepburn from 'hepburn';

const AnnotationModes = Object.freeze({
  FURIGANA: 0,
  ROMAJI: 1,
  NONE: 2,
});
const MODES_TEXT = ['Furigana', 'Romaji', 'None'];

const AnnotationSettings = ({ currentMode, updateMode }) => {

  const ModeSetting = ({ modeToSet }) => {
    let className = 'annotation-mode';
    if (currentMode === modeToSet) {
      className += ' selected';
    }
    return (
      <div onClick={() => updateMode(modeToSet)} className={className} role='button'>
        {MODES_TEXT[modeToSet]}
      </div>
    );
  };

  const modeSettings = Object.values(AnnotationModes).map(mode =>
    <ModeSetting modeToSet={mode} key={mode} />);
  return (
    <div className='annotation-settings'>
      <div>Annotations:</div>
      <div className='annotation-modes'>
        {modeSettings}
      </div>
    </div>
  );
};

// Example input: (主=しゅ)の(御座=みざ)の(前=まえ) (栄光=えいこう)(包=つつ)まれ
function annotate(s, mode) {
  const {ROMAJI, NONE} = AnnotationModes;
  const ret = [];
  const parts = s.split(/\((.+?=.+?)\)/); // `?` makes it non-greedy
  for (const p of parts) {
    const [text, ruby] = p.split('=');
    if (ruby && mode !== NONE) {
      const a = (mode === ROMAJI)
        ? hepburn.fromKana(ruby).toLowerCase().replace(/[^\w]/, '')
        : ruby;
      ret.push(<ruby>{text}<rt>{a}</rt></ruby>);
    } else if (mode === ROMAJI && hepburn.containsKana(text)) {
      for (const char of text) {
        const a = hepburn.fromKana(char).toLowerCase();
        ret.push(a.match(/\w/)
          ? <ruby>{char}<rt>{a}</rt></ruby>
          : char);
      }
    } else {
      ret.push(text);
    }
  }
  return ret;
}

export {
  AnnotationModes,
  AnnotationSettings,
  annotate,
};
