import './App.css';
import About from './components/About';
import Donate from './components/Donate';
import EditSong from './components/EditSong';
import Header from './components/Header';
import Home from './components/Home';
import Newsletter from './components/Newsletter';
import React, { useEffect } from 'react';
import Redirector from './components/Redirector';
import SongView from './components/SongView';
import { Route, useLocation } from 'react-router-dom';
import { faMusic } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(fab, faMusic);

// See for details:
// https://reacttraining.com/react-router/web/guides/scroll-restoration
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const Content = () => (
  <div className="content">
    {/* ---------------------------------------------------
        If modifying, then update offline/sitemap/routes.js
        --------------------------------------------------- */}
    <Route exact path="/" component={Home} />
    <Route exact path="/about" component={About} />
    <Route exact path="/song/:key" component={SongView} />
    <Route exact path="/edit/:key" component={EditSong} />

    {/* Redirects from legacy figsfoundation site URLs. Prevent link rot. */}
    <Route exact path="/lyrics/2019/07/20/nothing-but-the-blood-japanese-lyrics.html" render={(props) => <Redirector {...props} path="/song/nothing-but-the-blood"/>}/>
    <Route exact path="/announcement/2017/04/28/annoucing-the-figs-foundation.html" render={(props) => <Redirector {...props} path="/about"/>}/>
    <Route exact path="/announcement/2017/06/15/new-song-released-jesus-paid-it-all.html" render={(props) => <Redirector {...props} path="/song/jesus-paid-it-all"/>}/>
    <Route exact path="/announcement/2017/11/02/jesus-paid-it-all-video-in-japanese-with-lyrics.html" render={(props) => <Redirector {...props} path="/song/jesus-paid-it-all"/>}/>
    <Route exact path="/announcement/2017/11/05/jesus-paid-it-all-japanese-lyrics.html" render={(props) => <Redirector {...props} path="/song/jesus-paid-it-all"/>}/>
    <Route exact path="/lyrics/2019/02/21/how-great-is-our-god-japanese-lyrics.html" render={(props) => <Redirector {...props} path="/song/how-great-is-our-god"/>}/>
    <Route exact path="/lyrics/2019/07/20/king-of-my-heart-japanese-lyrics.html" render={(props) => <Redirector {...props} path="/song/king-of-my-heart"/>}/>

    <Route exact path="/donate/" component={Donate} />
    <Route exact path="/music/" render={(props) => <Redirector {...props} path="/about"/>} />
    <Route exact path="/newsletter/" component={Newsletter} />
  </div>
);

class App extends React.Component {
  render() {
    return (
      <div className="app">
        <ScrollToTop />
        <Header />
        <Content />
      </div>
    );
  }
}

export default App;
