import React from 'react';
import { connect } from 'react-redux';
import './EditSong.css';
import { updateSongText } from '../actions';
import { Link } from 'react-router-dom';
import { RenderSong } from './SongRenderer';


class EditSong extends React.Component {
    constructor(props) {
        super(props);
        for (var i = 0; i < this.props.songs.length; i++) {
            if (this.props.songs[i].key === this.props.match.params.key) {
                this.state = { song: this.props.songs[i].lyrics };
            }
        }
    }

    handleChange = event => {
        this.setState({ song: event.target.value });
    }

    render() {
        var text = "";
        if (this.state) {
            text  = this.state.song.split("\n");
        } else {
            return (
                <p>We don't have a song with that title.</p>
            );
        }
        const {items} = RenderSong(text);

        return (
            <div className="edit-song">
                <Link to={"/song/" + this.props.match.params.key}>Back (save not available yet)</Link>
                <p>If you made changes that you want to save, copy plaintext below and email it to <a href="mailto:figsfoundation@gmail.com">figsfoundation@gmail.com</a>.</p>
                <br/>
                <div className="song">
                    <div className="song-content">
                        <textarea className="song-text"
                            onChange={(event) => this.handleChange(event)} value={this.state.song}>
                        </textarea>
                    </div>
                    <div className="edit-song-display">

                        <div className="edit-song-display-content">
                            {items}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        songs: state.songs,
    }
}

export default connect(mapStateToProps, { updateSongText })(EditSong);
