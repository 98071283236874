import './EditSong.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

const hasChords = /^#chords /m;

const SongPreview = ({ song }) => (
  <Link to={'/song/' + song.key} className='song-link'>
    <div className='song-preview'>
      <p>{song.title}</p>
    </div>
    {hasChords.test(song.lyrics) &&
      <FontAwesomeIcon icon='music' className='song-has-chords' />
    }
  </Link>
);

export default SongPreview;
