import './BrandLink.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandLink = ({ brand, href }) => (
  <a
    className="brand-link"
    href={href}
    target="_blank"
    rel="noopener noreferrer">
    <FontAwesomeIcon icon={['fab', brand]} />
  </a>
);

export default BrandLink;
