import './Home.css';
import React, { Component } from 'react';
import SongPreview from './SongPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux';
import { keepSongsWithSearchQuery } from '../actions';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { searchQuery: "" }
    }

    componentDidMount() {
        document.title = "Figs Foundation";
    }

    handleChange = (event) => {
        const searchQuery = event.target.value;
        this.props.keepSongsWithSearchQuery(searchQuery);
        this.setState({ searchQuery });
    }

    render() {
        const {searchQuery} = this.state;
        const {searchResults, songs} = this.props;

        const displaySongs = searchQuery ? searchResults : songs;
        const displayResult = displaySongs.length
          ? displaySongs.map(song => <SongPreview song={song} key={song.key} />)
          : <p>No results</p>;

        return (
          <div className="home">
            <input
              id="search-bar"
              type="text"
              placeholder="Search songs and lyrics"
              onChange={this.handleChange} />
            <div className="song-help">
              <FontAwesomeIcon icon='music' />
              <span> = song with chords</span>
            </div>
            <div className="song-previews">
              {displayResult}
            </div>
          </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        songs: state.songs,
        searchResults: state.searchResults
    }
}

export default connect(mapStateToProps, { keepSongsWithSearchQuery })(Home);
